import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Materia extends Uniswap core, so the possibility to perform Flash swaps remains.`}</p>
    <p>{`Materia flash swaps allow you to withdraw up to the full reserves of any ITEM token (or WUSD) on Materia and execute arbitrary logic at no upfront cost, provided that by the end of the transaction you either:`}</p>
    <ul>
      <li parentName="ul">{`pay for the withdrawn tokens with the corresponding pair tokens`}</li>
      <li parentName="ul">{`return the withdrawn tokens along with a small fee`}</li>
    </ul>
    <p>{`Flash swaps are incredibly useful because they obviate upfront capital requirements and unnecessary order-of-operations constraints for multi-step transactions involving Materia.`}</p>
    <h1 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#examples",
        "aria-label": "examples permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Examples`}</h1>
    <h2 {...{
      "id": "capital-free-arbitrage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#capital-free-arbitrage",
        "aria-label": "capital free arbitrage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Capital Free Arbitrage`}</h2>
    <p>{`One particularly interesting use case for flash swaps is capital-free arbitrage. It’s well-known that an integral part of Materia’s design is to create incentives for arbitrageurs to trade the Materia price to a “fair” market price. While game-theoretically sound, this strategy is accessible only to those with sufficient capital to take advantage of arbitrage opportunities. Flash swaps remove this barrier entirely, effectively democratizing arbitrage.`}</p>
    <p>{`Imagine a scenario where the cost of buying 1 ETH on Materia is 200 DAI (which is calculated by calling `}<inlineCode parentName="p">{`getAmountIn`}</inlineCode>{` with 1 ETH specified as an exact output), and on Oasis (or any other trading venue), 1 ETH buys 220 DAI. To anyone with 200 DAI available, this situation represents a risk-free profit of 20 DAI. Unfortunately, you may not have 200 DAI lying around. With flash swaps, however, this risk-free profit is available for anyone to take as long as they’re able to pay gas fees.`}</p>
    <h3 {...{
      "id": "withdrawing-eth-from-materia",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#withdrawing-eth-from-materia",
        "aria-label": "withdrawing eth from materia permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Withdrawing ETH from Materia`}</h3>
    <p>{`The first step is to `}<em parentName="p">{`optimistically`}</em>{` withdraw 1 ETH from Materia via a flash swap. This will serve as the capital that we use to execute our arbitrage. Note that in this scenario, we’re assuming that:`}</p>
    <ul>
      <li parentName="ul">{`1 ETH is the pre-calculated profit-maximizing trade`}</li>
      <li parentName="ul">{`The price has not changed on Materia or Oasis since our calculation`}</li>
    </ul>
    <p>{`It may be the case that we’d like to calculate the profit-maximizing trade on-chain at the moment of execution, which is robust to price movements. This can be somewhat complex, depending on the strategy being executed. However, one common strategy is trading as profitably as possible `}<em parentName="p">{`against a fixed external price`}</em>{`. (This price may be e.g. the average execution price of one or more orders on Oasis.)`}</p>
    <h3 {...{
      "id": "trade-at-external-venue",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#trade-at-external-venue",
        "aria-label": "trade at external venue permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Trade at External Venue`}</h3>
    <p>{`Once we’ve obtained our temporary capital of 1 ETH from Materia, we now can trade this for 220 DAI on Oasis. Once we’ve received the DAI, we need to pay Materia back. We’ve mentioned that the amount required to cover 1 ETH is 200 DAI, calculated via `}<inlineCode parentName="p">{`getAmountIn`}</inlineCode>{`. So, after sending 200 of the DAI back to the Materia pair, you’re left with 20 DAI of profit!`}</p>
    <h2 {...{
      "id": "instant-leverage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instant-leverage",
        "aria-label": "instant leverage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Instant Leverage`}</h2>
    <p>{`Flash swaps can be used to improve the efficiency of levering up using lending protocols and Materia.`}</p>
    <p>{`Consider Maker in its simplest form: a system which accepts ETH as collateral and allows DAI to be minted against it while ensuring that the value of the ETH never drops below 150% of the value of the DAI.`}</p>
    <p>{`Say we use this system to deposit a principal amount of 3 ETH, and mint the maximum amount of DAI. At a price of 1 ETH / 200 DAI, we receive 400 DAI. In theory, we could lever this position up by selling the DAI for more ETH, depositing this ETH, minting the maximum amount of DAI (which would be less this time), and repeating until we’ve reached our desired leverage level.`}</p>
    <p>{`It’s quite simple to use Materia as a liquidity source for the DAI-to-ETH component of this process. However, looping through protocols in this way isn’t particularly elegant, and can be gas-intensive.`}</p>
    <p>{`Luckily, flash swaps enable us to withdraw the `}<em parentName="p">{`full`}</em>{` ETH amount upfront. If we wanted 2x leverage against our 3 ETH principal, we could simply request 3 ETH in a flash swap and deposit 6 ETH into Maker. This gives us the ability to mint 800 DAI. If we mint as much as we need to cover our flash swap (say 605), the remainder serves as a safety margin against price movements.`}</p>
    <h1 {...{
      "id": "developer-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#developer-resources",
        "aria-label": "developer resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developer resources`}</h1>
    <ul>
      <li parentName="ul">{`To see how to integrate a flash swap in your smart contract read `}<a parentName="li" {...{
          "href": "/docs/materia/smart-contract-integration/using-flash-swaps/"
        }}>{`Using Flash Swaps`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      